import React, { Component } from 'react';

const year = new Date().getFullYear();

class RealFooter extends Component {
  render() {
    return (
      <>
      <footer className="py-8 py-md-11 bg-white">

        <div className="container">
          <div className="row">

            <div className="col-12 mb-8">        

              <p className="text-gray-700 mb-2 text-muted small">
                <img src="/static/img/brand.svg" alt="..." className="footer-brand img-fluid mb-2" style={{ maxWidth: '3rem'}}/>
                &nbsp; Copyright &copy; {year+' '}Real Mobile Apps Limited trading as "Real" and "The Real Dating App".
              </p> 
            </div> 

            <div className="col-6 col-md-4 col-xl-2">
              <h6 className="fw-bold text-uppercase text-gray-700">
                Terms
              </h6>

              <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                <li className="mb-3">
                  <a href="/terms" className="text-reset">
                    Terms of Service
                  </a>
                </li>               
              </ul>    
            </div>

            <div className="col-6 col-md-4 col-xl-2">
              <h6 className="fw-bold text-uppercase text-gray-700">
                Privacy
              </h6>

              <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                <li className="mb-3">
                  <a href="/privacy" className="text-reset">
                    Privacy Policy
                  </a>
                </li>               
              </ul>    
            </div>              

          </div>       
        </div>

      </footer>
      </>
    )
  }
}

export default RealFooter;
