import React, { Component } from 'react';

import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import RealNavbar from '../partials/RealNavbar';
import RealFooter from '../partials/RealFooter';

import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';

const actionCodeSettings = {
  url: (window.location.hostname === 'realdatingapp.com') ? 
  `https://realdatingapp.com/join?r=${window.location.pathname}` : `http://localhost:3000/join?r=${window.location.pathname}`,
  handleCodeInApp: true,
};

class RealHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      emailValue: '',
      isInvalidEmail: true,
      isLoadingHidden: true,
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleModalClosure = this.handleModalClosure.bind(this);
  }

  handleEmailChange(e) {
    // eslint-disable-next-line no-control-regex
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    this.setState({ emailValue: e.target.value, isInvalidEmail: !emailRegex.test(e.target.value) });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.state.isInvalidEmail) {
      console.log('An email was submitted: ' + this.state.emailValue);
      const email = this.state.emailValue;

      // Switch to loading state
      this.setState({ 
        emailValue: '', 
        isInvalidEmail: true,
        isLoadingHidden: false
      });

      // Send Firebase Email Link
      const auth = getAuth();
      sendSignInLinkToEmail(auth, email, actionCodeSettings).then(() => {
        window.localStorage.setItem('emailForSignIn', email);
        this.setState({ showModal: true });
      }).catch((err) => {
        // TODO! show error modal
        console.log(err);
        this.setState({ showModal: true });        
      });

    }
  }

  handleModalClosure() {
    this.setState({ isLoadingHidden: true, showModal: false });
  }

  render() {
    return (
      <>
        <div className="home">

          <RealNavbar />

          <Modal show={this.state.showModal} onHide={this.handleModalClosure}>
            <Modal.Body>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleModalClosure}></button>

              <div className="text-center">
                <img src="/static/img/undraw/undraw_mail_sent_re_0ofv.svg" alt="..." className="img-fluid mb-3" style={{ width: '200px' }} />
              </div>

              <h2 className="fw-bold text-center mt-2" id="realModalTitle">
                Verify your email
              </h2>

              <p className="fs-lg text-center text-muted mb-4">
                Tap on the link in the email we sent you to join the waitlist for Real
              </p>

              <div className="row">
                <div className="col-12">
                  <button className="btn w-100 btn-primary mt-3 lift" onClick={this.handleModalClosure}>
                    Okay I understand
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <section className="pt-4">
            <div className="container">
              <div className="row align-items-center justify-content-center justify-content-md-between">

                <div className="col-12 col-md-6" data-aos="fade-right">

                  <h1 className="fw-bold">
                    It's time to get <span className="text-primary">Real.</span> <br />
                    The <span className="text-primary">Real</span> Dating App.
                  </h1>

                  <h4 className="fw-bold text-muted">Invite-only for now. Request access.</h4>

                  <p className="fs-lg text-muted mb-6">
                    Add your email below to join the waitlist and get premium features for free when you get invited.
                  </p>

                  <form className="mb-6 mb-md-8 mb-md-0 order-md-1" onSubmit={this.handleSubmit}>
                    <div className="row gx-4">
                      <div className="col-xs-12 col-lg-8 mb-6 mb-lg-0">
                        <input type="email" value={this.state.emailValue} onChange={this.handleEmailChange} placeholder="Type your email here" className="form-control bg-light border-2" />
                      </div>
                      <div className="col-xs-12 col-lg-4">
                        <button disabled={this.state.isInvalidEmail} type="submit" className="btn btn-primary btn-block">
                          <Spinner
                            hidden={this.state.isLoadingHidden}
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="visually-hidden">Loading...</span>
                          <span hidden={!this.state.isLoadingHidden}>Join now</span>
                        </button>
                      </div>
                    </div>
                  </form>

                </div>

                <div className="col-10 col-sm-8 col-md-6 order-md-2 d-none d-md-block">

                  <div className="device-combo device-combo-iphonex-iphonex mb-6 mb-md-0">

                    <div className="device device-iphonex">
                      <img src="/static/img/screenshots/mobile/real-john.jpg" className="device-screen" alt="..." />
                      <img src="/static/img/devices/iphonex.svg" className="img-fluid" alt="..." />
                    </div>

                    <div className="device device-iphonex">
                      <img src="/static/img/screenshots/mobile/real-jane.jpg" className="device-screen" alt="..." />
                      <img src="/static/img/devices/iphonex.svg" className="img-fluid" alt="..." />
                    </div>

                  </div>

                </div>

              </div>
            </div>
          </section>

          <section className="pt-6">
            <div className="container">
              <div className="row align-items-center justify-content-between">

                <div className="col-12 col-md-6 mb-5 mb-md-0">
                  <div className="row">
                    <div className="col-6 me-n5">

                      <img src="/static/img/unsplash/andre-tan-nX0mSJ999Og-unsplash.jpg" alt="..." className="img-fluid mb-4 rounded" data-aos="fade-right" data-aos-delay="100" />

                      <img src="/static/img/unsplash/brittani-burns-RbdTikirpz0-unsplash.jpg" alt="..." className="img-fluid rounded" data-aos="fade-right" data-aos-delay="150" />

                    </div>
                    <div className="col-6 mt-8">

                      <img src="/static/img/unsplash/dan-ImFTlibI2qA-unsplash.jpg" alt="..." className="img-fluid mb-4 rounded" data-aos="fade-right" />

                      <img src="/static/img/unsplash/justin-chen-J7Cf1Gch49E-unsplash.jpg" alt="..." className="img-fluid rounded" data-aos="fade-right" data-aos-delay="50" />

                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-6" data-aos="fade-left">
                  <h2 className="fw-bold">
                    Meet <span className="text-primary">Real </span> people.
                  </h2>
                  <p className="fs-lg text-muted mb-4">
                    Everyone on Real has to upload a profile video, which is much more real than boring profile pictures.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="py-8 py-md-11">
            <div className="container">
              <div className="row align-items-center justify-content-between">

                <div className="col-12 col-md-6 col-lg-7 order-md-2">
                  <img src="/static/img/undraw/undraw_intense_feeling_ft-9-s.svg" alt="..." className="img-fluid mb-8 mb-md-8 mb-md-0" data-aos="fade-left" />
                </div>

                <div className="col-12 col-md-6 col-lg-5 order-md-1" data-aos="fade-right">
                  <h2 className="fw-bold">
                    Make <span className="text-primary">Real </span> connections.
                  </h2>
                  <p className="fs-lg text-muted mb-0">
                    Send and receive intro videos to start chatting. Crack jokes or talk about serious topics, just be yourself, be real.</p>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-6 pt-md-8 d-none">
            <div className="container pb-6 pb-md-8 border-bottom">
              <div className="row align-items-center">
                <div className="col-12 col-md">

                  <h3 className="fw-bold mb-4">
                    It's time to get <span className="text-primary">Real </span> now.
                  </h3>

                </div>

                <div className="col-auto">

                  <a href="#!" className="mb-4 mb-md-0 text-reset d-inline-block me-1">
                    <img src="assets/img/buttons/button-app.png" className="img-fluid" alt="..." style={{ maxWidth: '155px' }} />
                  </a>

                  <a href="#!" className="text-reset d-inline-block">
                    <img src="assets/img/buttons/button-play.png" className="img-fluid" alt="..." style={{ maxWidth: '155px' }} />
                  </a>

                </div>
              </div>
            </div>
          </section>

          <RealFooter />
        </div>
      </>
    )
  }
}

export default RealHome;
