import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

class RealNavbar extends Component {
  render() {
    return (
      <>
      <Navbar bg="white" variant="light" data-aos="fade-right">
        <Container>

          <Navbar.Brand href="/">
            <img
              alt="Real Logo"
              src="/static/img/brand.svg"
              width="64"
              height="64"
              className="d-inline-block align-top"
            /> {' '}
            <span style={{ lineHeight: '64px' }}>Real</span>
          </Navbar.Brand>

          <div className="navbar-nav">
            <a className="nav-link" href="mailto:admin@realdatingapp.com">
              Contact Us
            </a>            
          </div>
        </Container>
      </Navbar>
      </>
    )
  }
}

export default RealNavbar;
